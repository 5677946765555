//* Variables ==================================== *//

/* Media Queries & Breakpoints
	https://include-media.com/
	=========================================== */

/*
| Name       | Code      | Short-Code | Em        | Px     |
| -----------|-----------|------------|-----------|--------|
| XXX-Small	 | xxxsmall	 | xxxs		  | 22.5em 	  | 360px  |
| XX-Small	 | xxsmall	 | xxs		  | 23.4375em | 375px  |
| X-Small	 | xsmall	 | xs		  | 26.5625em | 425px  |
| Small      | small     | s          | 40em      | 640px  |
| Medium     | medium    | m          | 48em      | 768px  |
| Large      | large     | l          | 60em      | 960px  |
| X-Large    | xlarge    | xl         | 75em      | 1200px |
| XX-Large   | xxlarge   | xxl        | 90em      | 1440px |
| XXX-Large  | xxxlarge  | xxxl       | 100em     | 1600px |
*/

// Breakpoints
$breakpoints-xxxsmall:	22.5em;
$breakpoints-xxsmall:	23.4375em;
$breakpoints-xsmall:	26.5625em;
$breakpoints-small:		40em;
$breakpoints-medium:	48em;
$breakpoints-large:		60em;
$breakpoints-xlarge:	75em;
$breakpoints-xxlarge:	90em;
$breakpoints-xxxlarge:	100em;

// Wrappers
$wrappers-site:				100vw;
$wrappers-small-desktop:	1200px;
$wrappers-default:			1600px;
$wrappers-wide:				1920px;
$wrappers-ultrawide:		2560px;

// Wrappers Padding
$wrapper-padding-small:		16px;
$wrapper-padding-medium:	26px;
$wrapper-padding-large:		32px;
$wrapper-padding-xxlarge:	30px;
$wrapper-padding-xxxlarge:	26px;

// Fonts: Family
$font-family-heading: ("Roboto Condensed", Helvetica, Arial, sans-serif);
$font-family-copy: ("Roboto", Arial, sans-serif);
$font-family-cta: ("Roboto",  Arial, sans-serif);

$font-family-primary: $font-family-copy;
$font-family-secondary: $font-family-cta;

// Fonts: Icons
@import "extensions/icons/lib/webfont/variables";
$font-family-icons: ($icomoon-font-family, sans-serif);

// Fonts: Base Settings
$html-font-size: 100%;
$font-size: 16px;
$font-size-incrimentor: 0.333;

// Fonts: Size
$font-size-xsmall: 12px;
$font-size-small:  14px;
$font-size-medium: 16px;
$font-size-large:  24px;
$font-size-xlarge: 32px;

// Fonts: Heading Size
$h1-size: 61px;
$h2-size: 48px;
$h3-size: 39px;
$h4-size: 31px;
$h5-size: 25px;
$h6-size: 20px;

// Fonts: Weights (https://gist.github.com/steveosoule/8275c2dd2ec21388aaf319b5d2cd5a71#bigelowandholmestypepadcombigelow-holmes201507on-font-weighthtml)
$font-weight-ultrathin: 100;
$font-weight-light:     200;
$font-weight-book:      350;
$font-weight-normal:    400;
$font-weight-medium:    500;
$font-weight-semibold:  600;
$font-weight-bold:      700;
$font-weight-black:     900;

$line-height: 1.25;

$heading-font-weight: $font-weight-bold;
$heading-line-height: $line-height;

$subheading-font-weight: $font-weight-medium;

// Colors
$white: #FFFFFF;
$black: #363636;
$gray-5: #575A5D;
$gray-4: #979797;
$gray-3: #CCCCCC;
$gray-2: #DDDDDD;
$gray-1: #F5F5F5;
$gray-0: #FAF8F8;
$red: #C5291F;
$transparent: rgba(0, 0, 0, 0);

// Original base Rallyhouse Colors, used in emails and a fallback for the `microsites.rallyhouse.com` site
$base-primary: #D42E12;
$base-secondary: #AA240D;
$base-tertiary: #FDF6F5;

// Colors: Theme
$primary: var(--rh-color-primary);
$secondary: var(--rh-color-secondary);
$tertiary: var(--rh-color-tertiary);

// Colors: Fonts
$main-text-color: #363636;

// Colors: Pricing
$price-color: #363636;
$base-price-color: #575A5D;
$sale-price-color: #B13138;

// Colors: Messages
$message-border: 1px solid currentColor;
$message-border-radius: 3px;

$message-error-text: #B13138;
$message-error-border: #F9C4C7;
$message-error-background: #FBE3E3;

$message-warning-text: #65602E;
$message-warning-border: #E6E086;
$message-warning-background: #F8F8E1;

$message-success-text: #0A7448;
$message-success-border: #BBE1D3;
$message-success-background: #DAF5EB;

$message-info-text: #0C5298;
$message-info-border: #8FBBE6;
$message-info-background: #D8EBFF;

$message-neutral-text: $gray-5;
$message-neutral-border: $gray-4;
$message-neutral-background: $white;

$message-font-color: $white;
$message-danger-color: $message-error-text;
$message-discount-color: #B13138;

// Colors: Border
$border-color: #DDDDDD;

// Border: Radius
$radius-none:   0;
$radius-small:  3px;
$radius-medium: 4px;
$radius-large:  160px;

// Border: Width
$border-width-none:   0;
$border-width-small:  1px;
$border-width-medium: 3px;
$border-width-large:  4px;

// Border: Styles
$border-solid: $border-width-small solid $border-color;

// Box-Shadows
$shadow: 0 0 0 0 rgba(0, 0, 0, 0.2);
$shadow-inset: inset 0 0 8px 0 rgba(0, 0, 0, 0.2);

// Outline
$outline: 0;

// Animation
$transition-time: 0.25s;
$transition-easing: ease-in-out;
$transition: all $transition-time $transition-easing;

// Input
$input-autofill-background-color: #F4F7FD;
$input-autofill-border-color:     #98BAE2;
$input-background-color:          $white;
$input-border:                    $border-solid;
$input-color:                     #363636;
$input-disabled-background-color: #FAFAFA;
$input-disabled-color:            #CCCCCC;
$input-error:                     $primary;
$input-focus-color:               #363636;
$input-outline:                   $outline;
$input-radius:                    3px;
$input-shadow-active:             none;
$input-shadow:                    $shadow;
$input-transition:                left $transition-time ease, opacity $transition-time ease, border-color $transition-time ease, background-color $transition-time ease;

// Input: Heights
$input-height-small:	40px;
$input-height:			50px;
$input-height-large:	64px;
$input-height-huge:		80px;

// Keylines
$keyline-border: $input-border;

// Units
$unit:				16px;
$unit-xxsmall:		$unit * 0.125;
$unit-xsmall:		$unit * 0.25;
$unit-small:		$unit * 0.5;
$unit-medium:		$unit * 1.5;
$unit-large:		$unit * 2;
$unit-xlarge:		$unit * 4;
$unit-xxlarge:		$unit * 5;
$unit-xxxlarge:		$unit * 6;

// Spacers
$spacer-1:	5px;
$spacer-2:	10px;
$spacer-3:	20px;
$spacer-4:	25px;
$spacer-5:	30px;
$spacer-6:	50px;
$spacer-7:	60px;
$spacer-8:	80px;
$spacer-9:	100px;
$spacer-10:	130px;

// Units: Columns
$column-count: 12;
$gutter-width: $unit;
$gutter-unit:  $gutter-width * 0.5;

// Tables
$table-border:				$input-border;
$table-stripe-color:		$gray-1;
$table-row-padding:			$unit-small;
$table-cell-padding:		$unit-small;
$table-cell-padding-large:	$unit;
$table-cell-padding-xlarge:	$unit-large;

// Z-indexes
$layers-max:	9999;
$layers-min:	-9999;
$layers-n1:		-1;
$layers-0:		0;
$layers-1:		100;
$layers-2:		200;
$layers-3:		300;
$layers-4:		400;

// Generic Cards
$card-height:				100%;
$card-bg:					$gray-1;
$card-border-width:			0;
$card-border-color:			$gray-2;
$card-border:				$card-border-width solid $card-border-color;
$card-border-radius:		$radius-large;
$card-spacer-y:				$unit;
$card-spacer-x:				$unit;
$card-cap-bg:				$gray-2;
$card-inner-border-radius:	$radius-large;
$nav-tabs-link-active-bg:	$gray-2;
$enable-rounded:			1;

// Product Cards
$box-shadow: 0 0px 10px rgba(0, 0, 0, 0.15);

// Buttons
$button-background-color:	$black;
$button-border:				$border-width-medium solid $button-background-color;
$button-font-size:			$font-size;
$button-font-family:		$font-family-secondary;
$button-font-weight:		$font-weight-medium;
$button-radius:				$radius-small;

$button-width-xsmall:	85px;
$button-width-small:	100px;
$button-width-regular:	130px;
$button-width-large:	313px;

// Links
$link-color:				#363636;
$link-focus-border-color:	#8FBBE6;
$link-transition:			all $transition-time cubic-bezier(0.25, 0.8, 0.25, 1);

// Carousel
$ui-carousel-arrow-size: 45px;
$ui-carousel-icon-size: 11px;

// Container
$ui-container-spacing-xs: 	$unit * 2;
$ui-container-spacing-s: 	$unit * 3;
$ui-container-spacing-m: 	$unit * 4;
$ui-container-spacing-l: 	$unit * 6;

// Global Elements
$sticky-header-box-shadow: 0 7px 16px rgba(0, 0, 0, 0.04);

// Product Reviews & Ratings
$rating-color: #E8D32E;
$rating-empty-color: rgba(232, 211, 48, 0.3);
$rating-bar-color: #363636;
$rating-empty-bar-color: #DFDFDF;
$rating-verified-icon: #0A7448;

// Free Shipping
$free-shipping-bg-color: #F9F9F9;
$free-shipping-progress-color: #0A7448;
$free-shipping-progress-bg-color: #DDDDDD;

// Mega Menu (sprite image position, not output on front-end)
$mm-logo-size: $unit-large;

$mm-row-2: -($unit-large);
$mm-row-3: -($unit-large * 2);

$mm-col-2: -($unit-large);
$mm-col-3: -($unit-large * 2);
$mm-col-4: -($unit-large * 3);
$mm-col-5: -($unit-large * 4);
$mm-col-6: -($unit-large * 5);
$mm-col-7: -($unit-large * 6);
$mm-col-8: -($unit-large * 7);
$mm-col-9: -($unit-large * 8);
$mm-col-10: -($unit-large * 9);
$mm-col-11: -($unit-large * 10);
$mm-col-12: -($unit-large * 11);
$mm-col-13: -($unit-large * 12);
$mm-col-14: -($unit-large * 13);
$mm-col-15: -($unit-large * 14);
$mm-col-16: -($unit-large * 15);
$mm-col-17: -($unit-large * 16);
$mm-col-18: -($unit-large * 17);
$mm-col-19: -($unit-large * 18);
$mm-col-20: -($unit-large * 19);

/* PDP Bopis Height */
$product-bopis-height: 160px;