@import "variables";

/* Not Found Page
	=========================================== */

.g-ntfd-wrapper {
	display: flex;
	flex-direction: column;
	position: relative;

	@include media( ">=large" ) {
		margin: {
			bottom: $unit-large;
			top: 36px;
		};
	}
}

.g-ntfd-image {
	margin-bottom: $unit;
}

.g-ntfd-image__img {
	width: 100%;
}

.g-ntfd-search {
	background: $gray-1;
	display: flex;
	justify-content: center;
	margin: {
		left: -$unit;
		right: -$unit;
	};
	padding: 40px $unit 18px;

	@include media( ">=small" ) {
		padding: {
			bottom: $unit-large;
			top: 54px;
		};
	}

	@include media( ">=medium" ) {
		margin: {
			left: 0;
			right: 0;
		};
	}
}

.g-ntfd-search__inner {
	max-width: 410px;
}

.g-ntfd-search-header {
	text-align: center;
}

.g-ntfd-search-header__title {
	font: {
		size: $font-size-large;
		weight: $font-weight-medium;
	};
	margin-bottom: 8px;

	@include media( ">=small" ) {
		font-size: 28px;
	}
}

.g-ntfd-search-header__subtitle {
	font-size: $font-size-small;
	margin-bottom: $spacer-3;
}

.g-ntfd-search-form {
	background: $white;
	border: 1px solid $black;
	border-radius: $unit-xsmall;
	margin-bottom: $spacer-3;
	position: relative;
	transition: box-shadow 0.2s ease-in-out;

	.g-form-list__item {
		margin-bottom: 0;
	}

	.g-ntfd-search-form__input,
	.g-ntfd-search-form__button {
		height: 46px;
		line-height: 46px;
		padding-left: $unit;
		padding-right: $unit;

		@include media( ">=large" ) {
			padding-left: $spacer-3;
			padding-right: $spacer-3;
		}
	}

	.g-ntfd-search-form__button {
		color: $black;
		font-size: $font-size-medium;
		padding-bottom: 0;
		padding-top: 0;

		&:hover {
			background-color: $primary;
			color: $white;
		}
	}
}

.g-ntfd-search-tips {
	font-size: $font-size-xsmall;
}

.g-ntfd-search-tips__title {
	font-weight: $font-weight-semibold;
	margin-bottom: 4px;
}

.g-ntfd-search-tips__list-item:not(:last-child) {
	margin-bottom: 2px;
}

.g-ntfd-carousel {
	margin: {
		left: -$unit;
		right: -$unit;
	};
	padding: {
		bottom: $unit;
		top: $unit;
	};

	@include media( ">=medium" ) {
		margin: {
			left: 0;
			right: 0;
		};
	}

	@include media( ">=xlarge" ) {
		padding: {
			bottom: 0;
			top: $unit-large;
		};
	}
}